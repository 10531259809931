<template>
  <industrial-sec />
</template>

<script>
import IndustrialSec from "../../components/solutions/security-solutions/IndustrialSec.vue";
export default {
  components: { IndustrialSec },
  name: "industrial-security-page",
};
</script>
